import { useRef, useEffect } from "react";
import "./canvas.css";

export function Canvas(props) {
  const canvasRef = useRef(null);

  useEffect(() => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    //Our first draw

    // context.fillStyle = "#000000";
    // context.fillRect(24, 0, 1, 1);

    for (let i = 0; i < props.height; i++) {
        for (let j = 0; j < props.width; j++) {
            if (props.grid[i][j] === 1) {
                context.fillStyle = "#505050";
                context.fillRect(j, i, 1, 1);
            }
            else {
                context.fillStyle = "#070303";
                context.fillRect(j, i, 1, 1);
            }
        }
    }
  }, [props.grid, props.width, props.height]);

  return (
    <canvas
      className="canvas"
      style={{
        // blocky
        imageRendering: "pixelated",
        width: "100%",
        height: "100%",
        transition: "all 0.5s ease",
      }}
      width={props.width} //cols
      height={props.height} // rows
      ref={canvasRef}
      {...props}
      grid={undefined}
    />
  );
}
