import React from "react";
import "./App.css";

import Background from "./Components/Background/Background";
import Header from "./Components/Header/Header";
// import Nav from "./Components/Nav/Nav";
import About from "./Components/About/About";
import Experience from "./Components/Experience/Experience";
// import Portfolio from "./Components/Portfolio/Portfolio";
// import Contact from "./Components/Contact/Contact";
// import Footer from "./Components/Footer/Footer";

function App() {
  return (
    <div style={{position:"relative"}}>
      <Background />
      {/* <Cursor /> */}
      <div style={{position: "absolute", top:0, width: "100%"}}>
      <Header />
      <About />
      <Experience />
      {/* <Portfolio /> */}
      </div>
    </div>
  );
}

export default App;
