import React from "react";
import "./socials.css";
import { FaGithub, FaLinkedin, FaFacebook, FaInstagram } from "react-icons/fa6";
import { IconContext } from "react-icons";

const Socials = () => {
  return (
    <div className="socials">
      <IconContext.Provider value={{ className: "react-icons" }}>
        <a href="https://github.com/CSherv272" target="_blank" rel="noreferrer">
          <FaGithub />
        </a>
        <a
          href="https://www.facebook.com/callum.shervill.5"
          target="_blank"
          rel="noreferrer"
        >
          <FaFacebook />
        </a>
        <a
          href="https://www.linkedin.com/in/callum-shervill/"
          target="_blank"
          rel="noreferrer"
        >
          <FaLinkedin />
        </a>
        <a
          href="https://www.instagram.com/sherv272/"
          target="_blank"
          rel="noreferrer"
        >
          <FaInstagram />
        </a>
      </IconContext.Provider>
    </div>
  );
};

export default Socials;
