import React from "react";
import Socials from "./Socials";
import "./about.css";

const about = () => {
  return (
    <div className="about" id="about">
      <div className="about-content">
        <h1>Callum Shervill</h1>
        <h2>Student, Tutor, Developer</h2>
        <div className="btn">
          <a href="#contact">Contact Me</a>
        </div>
        <Socials />
      </div>
    </div>
  );
};

export default about;
