import React, { useState } from "react";
import "./header.css";
import { FaBars } from "react-icons/fa6";

const Header = () => {
  const [style, setStyle] = useState("header-content");

  const handleMenu = () => {
    console.log("clicked");

    if (style === "header-content") {
      setStyle("header-content open");
    } else {
      setStyle("header-content");
    }
  };

  return (
    <div className="header">
      <div className="inner-header">
        <div className="logo">
          <h1>
            <a href="/">Sherv</a>
          </h1>
        </div>
        <div className="menu">
          <FaBars onClick={handleMenu} />
        </div>
        <div className={style}>
          <a className="header-link" href="#experience">
            Experience
          </a>
          {/* <a className="header-link" href="#portfolio">
            Portfolio
          </a> */}
          <a className="header-link" href="#contact">
            Contact
          </a>
        </div>
      </div>
    </div>
  );
};

export default Header;
