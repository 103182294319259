import React from "react";
import "./experience.css";
import TOT from "../../Assets/TOT.png";
import IBM from "../../Assets/ibm.svg";

const experience = () => {
  const experience = [
    {
      title: "Technical Operations Manager",
      company: "The Overseas Teacher",
      location: "Canterbury, UK",
      date: "May 2021 - September 2022",
      description:
        "At The Overseas Teacher, I was responsible for the day-to-day operations of the company. This included managing the company's website.",
      image: TOT,
    },
    {
      title: "Peer Tutor",
      company: "The University of Kent",
      location: "Canterbury, UK",
      date: "September 2023 - June 2024",
      description:
        "At the University of Kent, I was in charge of one of the numerous homework clubs running in the School of Computing. I was responsible for helping students with their coursework and providing them with the necessary resources to succeed in their studies.",
      image:
        "https://pbs.twimg.com/profile_images/1572551333139431425/LgJa-ffn_400x400.jpg",
    },
    {
      title: "Software Developer Intern",
      company: "IBM",
      location: "Hursley, UK",
      date: "July 2024 - Present",
      description:
        "During my internship at IBM, I was a key contributor to the Spectrum Virtualise team, focusing on the development of IBM's FlashSystems products. I collaborated closely with senior engineers and participated in the full software development lifecycle. My work directly supported the advancement of IBM’s storage systems. Through this experience, I gained hands-on expertise in software development, problem-solving, and working within a dynamic, agile team environment.",
      image: IBM,
    },
  ];

  return (
    <div className="experience" id="experience">
      <div className="container">
        <h1>Experience</h1>
        <div className="experience-carosel">
          {experience.map((exp, index) => (
            <div key={index} className="experience-item">
              <img src={exp.image} alt="company logo" />
              <h2>{exp.title}</h2>
              <h3>{exp.company}</h3>
              <h4>{exp.location}</h4>
              <h4>{exp.date}</h4>
              <p>{exp.description}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default experience;
