import React, { useState, useEffect, useRef } from "react";
import { Canvas } from "./Canvas";
import "./background.css";

const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      // document width and height
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
};

const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

const checkNeighbours = (grid, x, y) => {
  let neighbours = 0;
  const cols = grid.length;
  const rows = grid[0].length;

  for (let i = -1; i < 2; i++) {
    for (let j = -1; j < 2; j++) {
      const col = (x + i + cols) % cols;
      const row = (y + j + rows) % rows;
      neighbours += grid[col][row];
    }
  }
  neighbours -= grid[x][y];
  return neighbours;
};

const neighbourGrid = (grid) => {
  const cols = grid.length;
  const rows = grid[0].length;
  const neighbourGrid = new Array(cols);
  for (let i = 0; i < cols; i++) {
    neighbourGrid[i] = new Array(rows).fill(0);
  }
  for (let col = 0; col < cols; col++) {
    for (let row = 0; row < rows; row++) {
      const neighbours = checkNeighbours(grid, col, row);
      neighbourGrid[col][row] = neighbours;
    }
  }
  return neighbourGrid;
};

//Initial Grid Setup
function Background() {
  const { width: windowWidth, height: windowHeight } = useWindowSize();

  const ColAmount = 50;
  const [RowAmount, setRowAmount] = useState(0);
  const [grid, setGrid] = useState([]);

  useEffect(() => {
    const handlescroll = () => {
      if (window.scrollY > 100 && windowWidth > 768) {
        document.querySelector("canvas").style.filter = `blur(20px)`;
      } else if (windowWidth < 768 && window.scrollY > 100) {
        document.querySelector("canvas").style.filter = `blur(3px)`;
      } else {
        document.querySelector("canvas").style.filter = `blur(0px)`;
      }
    };
    window.addEventListener("scroll", handlescroll);
    return () => window.removeEventListener("scroll", handlescroll);
  }, [windowWidth]);

  useEffect(() => {
    const handlemousemove = (e) => {
      const { clientX, clientY } = e;
      const x = Math.floor((clientX / windowWidth) * ColAmount);
      const y = Math.floor((clientY / windowHeight) * RowAmount);

      try {
        grid[y][x] = 1;

        if (y > 0) grid[y - 1][x] = 1;
        if (x > 0) grid[y][x - 1] = 1;
        if (y < RowAmount - 1) grid[y + 1][x] = 1;
        if (x < ColAmount - 1) grid[y][x + 1] = 1;
      } catch {}
      setGrid([...grid]);
    };

    window.addEventListener("mousemove", handlemousemove);
    return () => window.removeEventListener("mousemove", handlemousemove);
  }, [RowAmount, ColAmount, windowHeight, windowWidth, grid]);

  useEffect(() => {
    const cols = ColAmount;
    const rows = Math.ceil((windowHeight / windowWidth) * cols);

    setRowAmount(rows);

    console.log(rows, RowAmount, windowHeight, windowWidth, cols);
    const arr = new Array(rows);
    for (let i = 0; i < rows; i++) {
      arr[i] = new Array(cols).fill(0);
    }

    for (let i = 0; i < arr.length; i++) {
      for (let j = 1; j < arr[i].length; j++) {
        arr[i][j] = Math.random() > 0.5 ? 1 : 0;
      }
    }

    // return arr;
    setGrid(arr);
    // these are dependecies
    // if these change, run this function
  }, [windowWidth, windowHeight, ColAmount, RowAmount]);

  useInterval(() => {
    // Conway's Game of Life
    let oldgrid = JSON.parse(JSON.stringify(grid));
    let nGrid = neighbourGrid(grid);

    for (let i = 0; i < grid.length; i++) {
      for (let j = 1; j < grid[i].length; j++) {
        if (nGrid[i][j] < 2 || nGrid[i][j] > 3) {
          grid[i][j] = 0;
        } else if (grid[i][j] === 0 && nGrid[i][j] === 3) {
          grid[i][j] = 1;
        }
      }
    }

    let different = false;
    for (let i = 0; i < grid.length; i++) {
      for (let j = 0; j < grid[i].length; j++) {
        if (grid[i][j] !== oldgrid[i][j]) {
          different = true;
          break;
        }
      }
      if (different) {
        break;
      }
    }

    if (!different) {
      for (let i = 0; i < grid.length; i++) {
        for (let j = 0; j < grid[i].length; j++) {
          grid[i][j] = Math.random() > 0.5 ? 1 : 0;
        }
      }
    }

    // Randomize
    // for (let i = 0; i < grid.length; i++) {
    //     for (let j = 1; j < grid[i].length; j++) {
    //         grid[i][j] = Math.random() > 0.9 ? 1 : 0;
    //     }
    // }
    // console.log(grid)

    setGrid([...grid]);
  }, 100);

  return (
    <div className="background-container">
      <Canvas width={ColAmount} height={RowAmount} grid={grid} />
      {/* <div
        className="grid"
        style={{
          position: "absolute",
          top: 0,
          width: "100%",
          height: "100%",
          display: "flex",
          flexWrap: "wrap",
        }}
      >
        {grid.map((row, rowIndex) => {
          return row.map((col, colIndex) => {
            return (
              <div
                key={`${rowIndex}-${colIndex}`}
                className="cell"
                style={{
                  width: windowWidth / ColAmount + "px",
                  height: windowWidth / ColAmount + "px",
                  backgroundColor: col === 0 ? "black" : "grey",
                  border: col === 0 ? "1px solid #333" : "1px solid black",
                  textAlign: "center",
                  transition: "all 0.5s ease",
                }}
              ></div>
            );
          });
        })}
      </div> */}
    </div>
  );
}

export default Background;
